<template>
    <div>
      <div class="row g-gs">
        <div class="col-lg-12">
            <div class="card h-100">
                <div class="card-inner">
                    <div class="row">
                        <div class="col-md-6">
                            <h6><em class="icon ni ni-list pt-1"/> Details</h6>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <span class="form-label float-right pt-1"><em class="icon ni ni-building"/> Account</span>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select  show-search option-filter-prop="children" placeholder="Select An Account" @select="searchContactsByAccount" @search="accountChanged" class="invisibox" style="min-width: 300px; width: 100%;">
                                                <a-select-option v-for="acc in zh_AccountsList" v-bind:key="acc.zohoId" @click="selectAccount(acc)">
                                                    {{ acc.name }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" >Quote Owner</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input v-model="account.accountName" placeholder="Account Name" type="text" class="invisibox" :class="{'is-error' : $v.account.accountName.$invalid }" id="accountName"/> 
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-list"/> Subject</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                         <a-input v-model="quote.subject" placeholder="Subject" type="text" class="invisibox"  id="subject"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-user"/> Contact</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select show-search option-filter-prop="children" placeholder="Search Contacts" @search="contactChanged" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="quote.contactId" >
                                                <a-select-option v-for="acc in zh_ContactsList" v-bind:key="acc.zohoId">
                                                    {{ acc.firstName + ' ' + acc.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" ><em class="icon ni ni-calendar"/> Valid From</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-date-picker v-model="quote.validFrom" :disabledDate="disabledDate" :default-value="null" format="DD/MM/YYYY" class="invisibox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" ><em class="icon ni ni-calendar-fill"/> Valid To</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-date-picker v-model="quote.validTo" :disabledDate="disabledDate" :default-value="$moment().add(30, 'days')" format="DD/MM/YYYY" class="invisibox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                         <div class="col-md-6">
                            <h6>£ Pricing</h6>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> SO One Off Revenue</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input-number step="0.01"  class="invisibox" v-model="quote.soOneOffRevenue"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> SO One Off GP</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input-number step="0.01"  class="invisibox" v-model="quote.soOneOffGP"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> SO Monthly Revenue</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input-number step="0.01" class="invisibox" v-model="quote.soMonthlyRevenue"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> SO Monthly GP</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input-number step="0.01" class="invisibox" v-model="quote.soMonthlyGP"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> Sub Total (£)</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            {{ subTotal }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> Tax (£)</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            {{ tax }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right pt-1"><em class="icon ni ni-sign-gbp"/> Grand Total (£)</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            {{ grandTotal }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <h5>Products / Items</h5>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <th><button v-on:click="addQuoteItem()" class="float-left btn btn-sm btn-icon btn-round btn-dim btn-outline-info btn-default"><em class="icon ni ni-plus"/></button></th>
                                    <th>Item Name</th>
                                    <!-- <th>Description</th> -->
                                    <th>Quantity</th>
                                    <th>Unit Price (£)</th>
                                    <th>Supplier Unit Price (£)</th>
                                    <!-- <th>List Price (£)</th>
                                    <th>Amount (£)</th> -->
                                    <!-- <th>Total (£)</th> -->
                                </thead>
                                <tbody>
                                    <tr v-for="product in quote.products" v-bind:key="product.id">
                                        <td style="width:30px;"><a-button v-on:click="deleteQuoteItem(product)" class="btn btn-sm btn-icon btn-round btn-dim btn-outline-danger btn-default"><em  class="icon ni ni-delete"/></a-button></td>
                                        <td>
                                            <a-select show-search option-filter-prop="children" placeholder="Search Products" @search="productChanged" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="product.zohoId">
                                                <a-select-option v-for="acc in zh_ProductsList" v-bind:key="acc.zohoId" @click="selectProduct(acc)">
                                                    {{ acc.name }}
                                                </a-select-option>
                                            </a-select>
                                        </td>
                                        <td><a-input-number class="invisibox" :min="1" v-model="product.quantity"/></td>
                                        <td>{{product.unitPrice}}</td>
                                        <td>{{product.supplierUnitPrice}}</td>
                                        <!-- <td><a-input-number class="invisibox" :min="1" v-model="product.listPrice"/></td>
                                        <td><a-input-number class="invisibox" :min="1" v-model="product.amount"/></td> 
                                        <td><a-input-number class="invisibox" :min="1" v-model="product.total"/></td> -->

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12">
                            <button :disabled="!canRaiseQuote" v-on:click="addQuote()" class="float-right btn btn-dim btn-outline-success btn-default"><em class="icon ni ni-plus"/> Save Quote</button>
                            <button v-on:click="openAddProduct()" class="float-left btn btn-dim btn-outline-dark btn-default"><em class="icon ni ni-plus"/> Add New Product</button> 
                        </div> 
                    </div>
                    <div class="row">
                        <p>To populate accounts and contacts, please start typing to search saved Zoho.</p>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="warning">
            {{ zh_AccountsList }}
        </div>

        <a-modal :width="900" v-model="addProductModal" title="Add Product">
            <div class="row">
                <div class="col-6">
                    <div class="col-12">
                        <label class="form-label">Product Name</label>
                        <a-input class="invisibox" v-model="newProduct.name"/>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Product Code</label>
                        <a-input class="invisibox" v-model="newProduct.productCode"/>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Product Type</label>
                        <a-input class="invisibox" v-model="newProduct.productType"/>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Charge Type</label>
                        <a-select class="invisibox" style="width: 100%;" placeholder="Select A Charge Type" v-model="newProduct.chargeType">
                            <a-select-option key="One Off">
                                One Off
                            </a-select-option>
                            <a-select-option key="Monthly">
                                Monthly
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="col-12" v-if="newProduct.chargeType == 'One Off'">
                        <label class="form-label">Unit Price</label>
                        <a-input-number step="0.01" style="width: 100%;" class="invisibox" v-model="newProduct.unitPrice"/>
                    </div>
                    <div class="col-12" v-if="newProduct.chargeType == 'Monthly'">
                        <label class="form-label">Rental Monthly</label>
                        <a-input-number step="0.01" style="width: 100%;" class="invisibox" v-model="newProduct.rentalMonthly"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="col-12">
                        <label class="form-label">Supplier</label>
                        <a-select  show-search option-filter-prop="children" placeholder="Select A Supplier" @search="supplierChanged" class="invisibox" style="min-width: 300px; width: 100%;" v-model="newProduct.supplierId">
                            <a-select-option v-for="acc in zh_SuppliersList" v-bind:key="acc.zohoId">
                                {{ acc.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Supplier Product ID</label>
                        <a-input class="invisibox" v-model="newProduct.supplierProductId"/>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Supplier Product Name</label>
                        <a-input class="invisibox" v-model="newProduct.supplierProductName"/>
                    </div>
                    <div class="col-12" v-if="newProduct.chargeType == 'One Off'">
                        <label class="form-label">Supplier Unit Price</label>
                        <a-input-number step="0.01" style="width: 100%;" class="invisibox" v-model="newProduct.supplierUnitPrice"/>
                    </div>
                    <div class="col-12" v-if="newProduct.chargeType == 'Monthly'">
                        <label class="form-label">Supplier Rental Monthly</label>
                        <a-input-number step="0.01" style="width: 100%;" class="invisibox" v-model="newProduct.supplierRentalMonthly"/>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <a-button @click="addProduct()" class="btn btn-sm btn-success"><em class="icon ni ni-save"/> Save</a-button>
            </template>
        </a-modal> 
    </div> 
</template>

<script>
import _ from 'lodash'

export default {
    props: {
        ticketId: Number
    },
    data () {
        return {
            defaultQuoteTemplate: {products: [{id: 1, name: '', quantity: 1, zohoId: null}], quoteOwnerId: null, subject: null, accountId: '', validFrom: null, validTo: null, contactId: ''},
            zh_AccountsList:[],
            zh_ContactsList:[],
            zh_ProductsList:[],
            zh_SuppliersList: [],
            accounts: [],
            product: {},
            newProduct: {productName: null},
            addProductModal: false,
            contacts: [],
            task: {
                description: null, 
                subject: null,
                ownerId: null
            },
            quoteNote: {ticketId: null, content: null},
            quote: {products: [{id: 1, name: null, quantity: 1, zohoId: null}], quoteOwnerId: null, subject: null, accountId: '', validFrom: null, validTo: null, contactId: null}
        }
    },
    created() {
        this.getlist()
        this.quote.validFrom  =  new this.$moment()
        this.quote.validTo = new this.$moment().add(30, 'days')
    },
    computed: {
        canRaiseQuote ()  {
            if (this.quote.contactId != null && this.quote.accountId != null && this.quote.subject != null
                && this.quote.products[0].zohoId != null
            )
            {return true} else
            {return false}
        },
        subTotal() { 
            let sum = 0;
            for(let i = 0; i < this.quote.products.length; i++){
                sum += (parseFloat(this.quote.products[i].unitPrice) * parseFloat(this.quote.products[i].quantity));
            }
            return sum;
        },
        tax() {
            let sum = 0;
            sum = (this.subTotal * 1.2) - this.subTotal
            return sum;
        },
        grandTotal() {
            let sum = 0;
            sum = this.subTotal + this.tax
            return sum;
        }
    },
    methods: {
        selectAccount(account) {
            this.selectedAccount = account
        },
        openAddProduct() {
            this.product = {}
            this.addProductModal = true
        },
        disabledDate(current) {
            // Can not select days before today and today
            return current && current < this.$moment().endOf('day');
        },
        addQuoteItem(){
            this.quote.products.push({id: this.quote.products.length + 1, name: '', quantity: 1})
        },
        deleteQuoteItem(product){
            this.quote.products.forEach(prd => {
                if (prd.id  === product.id) { 
                    this.quote.products.splice(this.quote.products.indexOf(prd),1)
                }
            });
        },
        debounceAccounts: _.debounce(function (accountName) {
            this.searchAccounts(accountName)
        }, 800),
        debounceSuppliers: _.debounce(function (supplierName) {
            this.searchSuppliers(supplierName)
        }, 800),
        debounceContacts: _.debounce(function (contactName) {
            this.searchContacts(contactName)
        }, 800),
        debounceProducts: _.debounce(function (productName) {
            this.searchProducts(productName)
        }, 800),
        accountChanged(accountName) {
            if (accountName) {
                this.debounceAccounts(accountName)
            } else {
                this.$message.error('Please type to search')
            }
        },
        supplierChanged(supplierName) {
            if (supplierName) {
                this.debounceSuppliers(supplierName)
            } else {
                this.$message.error('Please type to search')
            }
        },
        contactChanged(contactName) {
            if (contactName) {
                this.debounceContacts(contactName)
            } else {
                this.$message.error('Please type to search')
            }
        },
        productChanged(productName) {
            if (productName) {
                this.debounceProducts(productName)
            } else {
                this.$message.error('Please type to search')
            }
        },
        searchAccounts(accountName) { // Use zoho to fetch the accounts
             this.$http.get('/ZohoConnect/searchAccounts/' + accountName)
            .then((response) => {
                this.zh_AccountsList = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        searchSuppliers(supplierName) { // Use zoho to fetch the accounts
             this.$http.get('/ZohoConnect/searchSuppliers/' + supplierName)
            .then((response) => {
                this.zh_SuppliersList = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        selectProduct(acc) { 
            let productsArray = this.quote.products

            let product = {}
            product.id = productsArray.length
            product.zohoId = acc.zohoId
            product.quantity = 1
            product.unitPrice = acc.unitPrice
            product.supplierUnitPrice = acc.supplierUnitPrice

            for (var i = 0; i < productsArray.length; i++) {
                if (productsArray[i].id == product.id) {
                    productsArray.splice(i, 1, product)
                }
            }

            this.quote.products = productsArray
        },
        searchContactsByAccount() {  
            this.quote.accountId = this.selectedAccount.zohoId
            this.quote.billingStreet = this.selectedAccount.billingStreet
            this.quote.billingCity = this.selectedAccount.billingCity
            this.quote.billingCode = this.selectedAccount.billingCode
            this.quote.billingState = this.selectedAccount.billingState
            this.quote.billingCountry = this.selectedAccount.billingCountry
            this.quote.shippingStreet = this.selectedAccount.shippingStreet
            this.quote.shippingCity =   this.selectedAccount.shippingCity
            this.quote.shippingCode =   this.selectedAccount.shippingCode
            this.quote.shippingState = this.selectedAccount.shippingState
            this.quote.shippingCountry =   this.selectedAccount.shippingCountry
            this.$http.get('/ZohoConnect/searchContactsByAccount/' + this.quote.accountId)
            .then((response) => {
                this.zh_ContactsList = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        searchContacts(contactName) {// Use zoho to fetch the contacts - may need to pass acc id also.. 
            this.$http.get('/ZohoConnect/searchContacts/' + contactName)
            .then((response) => {
                this.zh_ContactsList = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        searchProducts(productName) {// Use zoho to fetch the products
             this.$http.get('/ZohoConnect/searchProducts/' + productName)
            .then((response) => {
                this.zh_ProductsList = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        addProduct() {// Add a new Product into zoho
             this.$http.post('/ZohoConnect/addProduct/',this.newProduct)
            .then(() => {
                this.$message.success('Product Saved')
                this.newProduct = {}
                this.addProductModal = false
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        addQuote() {// Raise a quote in zoho and create note on ticket.
            this.quote.tax = this.tax
            this.$http.post('/ZohoConnect/addQuote/',  this.quote)
            .then((response) => {
               this.addTask(response.data.zohoId)
               this.$message.success('Quote Raised')
               this.quote = JSON.parse(JSON.stringify(this.defaultQuoteTemplate))
               this.$emit('quoteSaved')
               this.quoteNote.ticketId = this.ticketId
               this.quoteNote.content = '<a target="_blank" href="https://crm.zoho.com/crm/org7160378/tab/Quotes/' + response.data.zohoId.toString() + '"> Zoho Quote Raised - ' + response.data.zohoId.toString() + '</a>'
               this.addQuoteNote()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        addTask(quoteId) {
            this.task.description = "Quote " + quoteId +  " has been raised on SoftSuite. Quote raised for TN" + this.ticketId + " . Please contact the quote owner for any questions."
            this.task.subject = "Please review a quote raised on SoftSuite"
            this.task.ownerId = this.selectedAccount.ownerId
            this.$http.post('/ZohoConnect/addTask/', this.task)
            .then(() => {
                // No need for response
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        addQuoteNote(){
            this.$http.post('/desk/Save_Ticket_Note/',  this.quoteNote)
            .then(() => {
                // No response needed
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getContacts() {
            this.$http.get('/accounts/Get_Account_Contacts/' + this.int_Ticket.accountId)
            .then((response) => {
                this.contacts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getlist(){
            this.$http.get('/lists/Get_List/accounts')
            .then((response) => {
                this.accounts = response.data
            })
            this.$http.get('/desk/get_AccountsAndContacts')
            .then((response) => {
                this.accountsAndContacts = response.data
            })
        }
    },
}
</script>

<style>

</style>