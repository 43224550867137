<template>
    <div>
        <div class="d-flex flex-column h-100">
            <div class="card-title-group mb-3">
                <div class="card-title">
                    <h6 class="title">Scheduled Status Automations</h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <vue-good-table v-if="scheduledStatusAutomations"
                :columns="columns"
                :rows="scheduledStatusAutomations"
                styleClass="vgt-table condensed tblhov"
                @on-cell-click="showEditIssueType"
                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'scheduledDate'">{{ $moment(props.row.scheduledDate).format("H:mm DD/MM/YY") }}</span>
                </template>
                </vue-good-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ticketId: { 
            type: Number
        }
    },
    data() {
        return {
            scheduledStatusAutomations: null,
            columns: [
                {
                    label: 'Scheduled Date',
                    field: 'scheduledDate'
                },{
                    label: 'From Status',
                    field: 'fromStatus'
                },{
                    label: 'To Status',
                    field: 'toStatus'
                }
            ],
        }
    },
    created() {
        this.getScheduledStatusAutomations()
    },
    methods: {
        getScheduledStatusAutomations() {
            this.$http.get('/desk/Get_Ticket_ScheduledStatusAutomations/' + this.ticketId)
            .then((response) => {
                this.scheduledStatusAutomations = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>