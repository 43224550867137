<template>
  <div :style="{ overflow: 'hidden', position: 'relative'}">
      <div class="card-title-group mb-3">
            <div class="card-title">
                <h6 class="title">Previous User Time Allocations</h6>
            </div>
        </div>
      <div class="timeline">
        <h6 class="timeline-head"></h6>
        <ul class="timeline-list">
            <li v-for="ticketTimeAllocation in ticketTimeAllocations" v-bind:key="ticketTimeAllocation.id" class="timeline-item">
                <div class="timeline-status" :style="{backgroundColor: 'red'}"></div>
                <div class="timeline-date"> {{ $moment.utc(ticketTimeAllocation.startTime).local().fromNow() }}</div>
                <div class="timeline-data" style="width: 100%">
                    <a-button v-if="$hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3')" class="float-right btn btn-dark btn-sm" @click="selectTicketStatus(ticketTimeAllocation)"><em class="icon ni ni-edit"/> Edit</a-button>
                    <div class="timeline-des">
                        <h6 class="timeline-title"><em class="icon ni ni-user-alt"/> {{ticketTimeAllocation.createdByuser}}</h6>
                        <p><em class="icon ni ni-calendar"/> {{ $moment.utc(ticketTimeAllocation.startTime).local().format('HH:mm a DD/MM/YYYY') }}</p>
                        <p v-if="ticketTimeAllocation.stopTime"><em class="icon ni ni-calendar-fill"/> {{ $moment.utc(ticketTimeAllocation.stopTime).local().format('HH:mm a DD/MM/YYYY') }}</p>
                        <p><em class="icon ni ni-alarm-alt"/> {{ ticketTimeAllocation.timeInStatusHHMMSS }}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <a-modal title="Edit Status Time" v-model="visible">
        <div class="row" v-if="selectedTicketTimeAllocation">
            <div class="col-lg-12">
                <div class="form-label">Change start time</div>
                <a-date-picker style="width:100%;" :showTime="true" format="HH:mm a DD/MM/YYYY" :defaultValue="$moment.utc(selectedTicketTimeAllocation.startTime).local()" v-model="selectedTicketTimeAllocation.startTime"></a-date-picker>
            </div>
            <div class="col-lg-12">
                <div class="form-label">Change stop time</div>
                <a-date-picker style="width:100%;" :showTime="true" format="HH:mm a DD/MM/YYYY" :defaultValue="$moment.utc(selectedTicketTimeAllocation.stopTime).local()" v-model="selectedTicketTimeAllocation.stopTime"></a-date-picker>
            </div>
        </div>
        <template slot="footer">
            <a-button class="btn btn-success btn-sm" @click="saveChange"><em class="icon ni ni-save"/> Save</a-button>
        </template>
    </a-modal>
  </div>
</template>

<script>
export default {
    props:{
        ticketId: Number,
        ticketTimeAllocations: Array
    },
    data() {
        return {
            params: {ticketId: null, userId: null},
            showUserInStatusSummary: false,
            visible: false,
            selectedTicketTimeAllocation: {}
        }
    },
    created() {

    },
    methods: {
        selectTicketStatus(timeAllocation) {
            this.selectedTicketTimeAllocation = timeAllocation
            this.selectedTicketTimeAllocation.startTime = this.$moment.utc(this.selectedTicketTimeAllocation.startTime).local()
            this.selectedTicketTimeAllocation.stopTime = this.$moment.utc(this.selectedTicketTimeAllocation.stopTime).local()
            this.visible = true
        },
        saveChange() {
            this.$http.post('/desk/update_Ticket_TimeAllocation', this.selectedTicketTimeAllocation)
            .then(() => {
                this.$emit('edited')
                this.visible = false
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }) 
        },
    }
}
</script>

<style>

</style>