<template>
    <div v-if="userInStatusSummary" class="row">   
        <div class="col-12">
            <!-- <div class="card-title-group mb-3">
                <div class="card-title">
                    <h6 class="title">{{ createdByUser }}</h6>
                </div>
            </div>
            <hr> -->
            <div class="progress-list gy-3">
                <div v-for="uiss in userInStatusSummary" v-bind:key="uiss.statusId" class="progress-wrap">
                    <div class="progress-text">
                        <div class="progress-label">{{uiss.status}} (<small class="text-muted">{{uiss.timeInStatusHHMMSS}}</small>)</div>
                    </div>
                    <a-progress :strokeColor="uiss.color" :showInfo="false" :percent="uiss.percentageInStatus"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        ticketId: Number,
        userId: String
    },
    data() {
        return {
            userInStatusSummary: null,
        }
    },
    watch : {
          ticketId () {
              this.getUserInStatusSummary()
          }
    },
    created() {
        this.getUserInStatusSummary()
    },
    methods: {
        getUserInStatusSummary(){
            let params  = {id: this.ticketId, userId: this.userId}
            this.$http.post('/desk/Get_Ticket_UserTimeInStatus/', params)
            .then((response) => {
                this.userInStatusSummary = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })  
        }
    }
}
</script>

<style>

</style>