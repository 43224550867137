<template>
    <div >   
        <div class="d-flex flex-column h-100">
            <div class="row">
                <div class="col-12">
                    <a @click="startTicketTimeAllocation()" class="btn btn-icon btn-md btn-success btn-round float-right pt-1"><em class="icon ni ni-play-fill" style="color: white;"/></a>
                    <h6 class="title pt-1">Active User Timers</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table" v-if="activeTimers.length">
                        <thead>
                            <th>User</th>
                            <th>Time Spent</th>
                            <th class="text-center">Actions</th>
                        </thead>
                        <tbody>
                            <tr v-for="a in activeTimers" :key="a.id">
                                <td>{{ a.createdByUser }}</td>
                                <td>{{ $moment(a.startTime).format('HH:mm a DD/MM/YYYY')}}</td>
                                <td class="text-center">
                                    <a @click="stopTicketTimeAllocation(a.userId)" class="btn-icon btn-danger btn-xs btn btn-round"><em class="icon ni ni-pause-fill" style="color: white;"/></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="text-center alert"> No users currently working on this ticket.</p>
                </div>
            </div>
            <hr class="preview-hr">
            <timeAllocatedTimeline @edited="getTimeline()" :ticketTimeAllocations="ticketTimeAllocations" :ticketId="ticketId"/>
        </div>
    </div>
</template>

<script>
import timeAllocatedTimeline from '@/components/desk/tickets/timeAllocatedTimeline.vue'

export default {
    components: {
        timeAllocatedTimeline
    },
    props:{
        ticketId: Number
    },
    data() {
        return {
            timeAllocation: {},
            activeTimers: null,
            ticketTimeAllocations: null,
        }
    },
    watch : {
          ticketId () {
              this.getActiveTicketTimeAllocation()
          } 
    },
    created() {
        this.getActiveTicketTimeAllocation()
        this.getTimeline()
    },
    methods: {
        startTicketTimeAllocation() {
            this.timeAllocation.ticketId = this.ticketId
            this.$http.post('/desk/Start_TicketTimeAllocation', this.timeAllocation)
            .then(() => {
                this.getActiveTicketTimeAllocation()
                this.$emit('reRender')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            }) 
        },
        getActiveTicketTimeAllocation() {
            this.$http.get('/desk/Get_Active_User_TimeAllocations/' + this.ticketId)
            .then((response) => {
                this.activeTimers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            }) 
        },
        stopTicketTimeAllocation(user){
            this.timeAllocation.ticketId = this.ticketId
            this.timeAllocation.userId = user
            this.$http.post('/desk/Stop_TicketTimeAllocation', this.timeAllocation)
            .then(() => {
                this.getActiveTicketTimeAllocation()
                this.getTimeline()
                // need to get prev user timers
            })
            .catch(() => {
                this.$message.error('There has been an error')
            }) 
        },
        getTimeline() {
            this.$http.get('/desk/Get_Ticket_TimeAllocation_Timeline/' + this.ticketId)
            .then((response) => {
                this.ticketTimeAllocations = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })  
        },
    }
}
</script>

<style>

</style>