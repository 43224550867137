<template>
  <div>
      <div  id="notes">
                  <div class="row">
                    <div class="col-md-12">
                     <div class="noteContainer bg-light" id="noteContainer" style="border-radius:10px">
                       <div id="editor">
                      
                      <editor ref="tiptapEditor" v-bind:output.sync="newNote.content"></editor>
                      <div class="row">
                        <div class="col-md-12">
                          <a-input type="text" v-model="searchFilter" allow-clear style="width:60%; margin: 10px; margin-top: 0px; margin-left:3%;" class="invisibox form-focus-none float-left" placeholder="Search Notes">
                              <a-icon slot="prefix" type="search" />
                          </a-input>
                          <button v-if="!newNote.id" v-on:click="saveNote()" class="mb-3 mr-3 btn btn-dark btn-sm float-right"><em class="icon ni ni-save mr-1"></em>Add Note</button>
                          <button v-if="newNote.id" v-on:click="saveNote()" class="mb-3 mr-3 btn btn-warning btn-sm float-right"><em class="icon ni ni-save mr-1"></em>Update Note</button>
                        </div>
                      </div>
                     </div>
                      <div id="commentsContent" class="commentsContent" :style="{maxHeight: maxHeight}">
                        <div  v-if="notes"  class="">
                          <div class="card card-bordered">
                              <ul class="nk-support" style="margin-bottom:0px;">
                                  
                                  <li style="position:relative;" class="nk-support-item" v-for="note in filteredNotes.slice()" :key="note.id" >
                                      <div class="">
                                          <avatar  :src="note.createdByUserAvatarURI" style="margin:2px;" :size="36" :rounded="true" v-if="note.createdByUser || note.createdByUserAvatarURI" :username="note.createdByUser" />
                                      </div>
                                      <div class="nk-support-content" >
                                          <div class="title">
                                              <span>{{note.createdByUser}}
                                                <span v-if="note.updatedByUser != null" style="font-size:10px">updated {{$moment.utc(note.updatedDate).local().format('H:mm a DD/MM/YY')}} by {{note.updatedByUser}}</span>
                                              </span>
                                              
                                              <!-- <span class="badge badge-dot badge-dot-xs   ml-1">{{$moment.utc(note.createdDate).locale('en').fromNow()}}</span> -->
                                              <a-popover placement="right" style="position:absolute; top:10px; right:10px;">
                                                <template slot="content">
                                                  <em v-if="note.createdDate" class="icon ni ni-calendar-alt-fill"></em>
                                                  <span v-if="note.createdDate">{{$moment.utc(note.createdDate).local().format('H:mm a DD/MM/YY')}}</span>
                                                </template>
                                                <div>
                                                  <span class="badge badge-dot badge-dot-xs   ml-1">{{$moment.utc(note.createdDate).locale('en').fromNow()}}</span>
                                                </div>
                                              </a-popover>
                                              <div style="position: absolute; bottom:10px; right:10px;">
                                                <a-popconfirm
                                                  title="Are you sure?"
                                                  ok-text="Yes"
                                                  ok-type="danger"
                                                  cancel-text="No"
                                                  @confirm="deleteNote(note)"
                                                  @cancel="cancel"
                                                >
                                                  <button class="btn btn-xs btn-danger mr-1"><em class="icon ni ni-trash"></em></button>
                                                </a-popconfirm>
                                                <button v-on:click="editNote(note)" class="btn btn-xs btn-dark"><em class="icon ni ni-edit"></em></button> 
                                              </div> 
                                          </div>
                                          <span style="overflow-wrap: anywhere;" v-html="note.content"></span> 
                                          <span style="padding: 5px;"></span>
                                      </div>
                                      
                                          
                                  </li>
                              </ul>
                          </div>
                        </div>
                      </div>
                      
                     </div>
                    </div>
                  </div>
              </div>
  </div>
</template>

<script>
import editor from '@/components/generic/notes/wysiwyg/full'
export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    entityName: {
      type: String,
      default: null
    },
    getEndpoint: {
      type: String,
      default: '/notes'
    },
    saveEndpoint: {
      type: String,
      default: '/addNote'
    },
    deleteEndpoint: {
      type: String,
      default: '/deleteNote'
    }
  },
  watch : {
          id:function() {
              this.resetNote()
              this.getNotes()
          }
        },
  data () {
    return {
      notes: null,
      newNote: {content: ''},
      searchFilter: '',
      loaded: false,
      maxHeight: '',
    }
  },
  created () {
    this.resetNote()
    this.getNotes()
  },
  computed: {
      filteredNotes: function() {
          if (this.searchFilter != '') {
              return this.notes.filter((f) => {  return f.content.toLowerCase().match(this.searchFilter.toLowerCase()) })
          }
          return this.notes 
      },
  },
  methods: {
    editNote (note) {
      this.newNote = JSON.parse(JSON.stringify(note))
      this.$refs.tiptapEditor.setContent(note.content)
    },
    cancel() {
      console.log('cancel')
    },
    deleteNote (note) { 
      this.$http.post(this.deleteEndpoint, note)
        .then(() => {
            this.getNotes()
            this.resetNote()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    resetNote () {
      this.newNote = {content: ''}
      this.$set(this.newNote, this.entityName + 'Id', this.id);
    },
    getMaxHeight() {
      const menuSize = (document.documentElement.scrollHeight - document.getElementById('ticketMenu').offsetHeight - document.getElementById('editor').offsetHeight - 50)
      this.maxHeight = menuSize + 'px'
    },
    saveNote () {
      if (this.newNote.content === '') {
        this.$message.error('Please do not save blank notes!')
      } else {
        this.$http.post(this.saveEndpoint, this.newNote)
        .then(() => {
            this.getNotes()
            this.resetNote()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      }
    },
    getNotes () {
      this.$http.get(this.getEndpoint)
      .then((response) => {
          this.notes = response.data
          this.loaded = true
          this.getMaxHeight()
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },
  },
components: {editor},
}
</script>

<style scoped>
.noteContainer {
  position: fixed;
  right: 0.5%;
  width: 100%;
  max-width: 560px;
  margin: 5px;
}
.commentsContent {
  overflow-y: auto;
}
.upIcon {
  font-size: 30px;
}
</style>