<template>
        <div class="ticketMenu" id="ticketMenu">
            <!-- <div v-on:click="saveTicket()" class="ticketMenuItemSave"><em class="float-left icon ni ni-save"></em></div> -->
            <!-- <div v-on:click="changeComponent('details')" class="ticketMenuItem ticketMenuDetails" style="width:189px; border-right:none;"><div class="ticketMenuName">Ticket TN{{ticketId}}</div></div> -->
            <!-- <div v-if="ticketId && completedDate == null" v-on:click="completeTicket()" class="ticketMenuItemSave">Complete Ticket</div> -->
            <!-- <div class="float-right"> -->
            <!-- <div v-on:click="changeComponent('notes')" style="border-left: 1px solid #e8e8e8;" class="ticketMenuItem"><em class="float-left icon ni ni-edit"></em></div> -->
            <!-- <div v-on:click="changeComponent('attachments')" class="ticketMenuItem"><em class="float-left icon ni ni-clip"></em></div> -->
            <!-- <div v-on:click="changeComponent('timeline')" class="ticketMenuItem"><em class="float-left icon ni ni-list-thumb"></em></div> -->
            <!-- <div v-on:click="changeComponent('')" class="ticketMenuItem"><em class="float-left icon ni ni-clock"></em></div> -->
            <!-- <div v-on:click="changeComponent('status')" class="ticketMenuItem"><em class="float-left icon ni ni-calendar"></em></div> -->
            <!-- <div v-on:click="closeTicket()" class="ticketMenuItem"><em class="float-left icon ni ni-cross"></em></div> -->
            
            <!-- </div> -->
            <div class="row">
                <div class="col-12">
                    <div class="float-left" style="margin-left:20px;">
                        <a href="#" @click="saveTicket()" title="Save Ticket" class="mr-2 btn btn-icon btn-dim btn-outline-success btn-round"><em class="icon ni ni-save"></em></a>
                        <a href="#" @click="changeComponent('details')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" style="font-weight:600; padding: 7px; font-size: 16px;">TN{{ticketId}}</a>

                    </div>
                    <div class="float-right " style="margin-right:20px;">
                        <a href="#" @click="changeComponent('notes')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-edit"/></a>
                        <a href="#" @click="changeComponent('attachments')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-clip"/></a>
                        <a href="#" @click="changeComponent('timeAllocation')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-clock"/></a>
                        <a href="#" @click="changeComponent('timeline')" class="mr-2 btn btn-icon btn-dim btn-outline-dark btn-round" ><em class="icon ni ni-list-thumb"/></a>
                        <a href="#" v-if="ticketId && completedDate == null" @click="completeTicket()" title="Complete Ticket" class="mr-2 btn btn-icon btn-dim btn-outline-success btn-round"><em class="icon ni ni-check"></em></a>
                        <a-dropdown :trigger="['hover']" placement="bottomLeft">
                            <a href="#" class="btn btn-icon btn-dim btn-outline-secondary btn-round"><em class="icon ni ni-more-h"></em></a>
                            <a-menu slot="overlay" class="menu">
                                <a-menu-item v-if="integrationSettings">
                                <div v-on:click="openZohoQuote()"><img style="width:20px; height:auto;" src="@/assets/images/icons/brands/zohoLogo.png" alt=""> <span style="padding-top:30px;">Raise Zoho Quote</span></div>
                                </a-menu-item>
                                
                                <a-menu-item>
                                <div @click="changeComponent('status')"><em class="icon ni ni-calendar"/> Status Schedules</div>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
                </div>
                <!-- zohoQuote -->
                <a-modal
                    title="Zoho Quote"
                    width="80%"
                    height="80%"
                    :visible="showZohoQuote" 
                    @cancel="closeZohoQuote"
                    :footer="null"
                    :destroyOnClose="true"
                >
                    <raiseZohoQuote  :ticketId="ticketId" @quoteSaved="quoteSaved()"></raiseZohoQuote>
                </a-modal>
                <!-- end zohoQuote -->
        </div>
</template>

<script>
import raiseZohoQuote from '@/components/integrations/zoho/raiseZohoQuote.vue'

export default {
    components: {
        raiseZohoQuote
    },
    props: {
        ticketId: {
            type: Number
        },
        completedDate: {
            type: Date
        },
    },
    data() {
        return {
            showZohoQuote: false,
            integrationSettings: null,
        }
    },
    methods: {
    changeComponent (changeTo) {
        this.$emit('changeTabs',changeTo)
    },
    completeTicket () {
        this.$emit('completeTicket')
    },
    saveTicket () {
         this.$emit('saveTicket')
    },
    closeTicket () {
         this.$emit('closeTicket')
    },
    openZohoQuote() {
        this.showZohoQuote = true;
    },
    quoteSaved() {
        this.$emit('changeTabs','notes')
        this.closeZohoQuote()
    },
    closeZohoQuote() {
        this.showZohoQuote = false;
    },
    checkForIntegationSettings() {
        this.$http.get('/settings/Get_Integration_Settings')
        .then((response) => {
          this.integrationSettings = response.data
          })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    }
}
}
</script>

<style>
.ticketMenuDetails {
    font-size: 16px;
    line-height: 17px;
    width:285px;
}
.ticketMenu {
    height: '2%';
    color: #fff;
    width:100%;
    padding-top: 7px;
}
.ticketMenu em {
    font-size: 20px;
}
.ticketMenu .ticketMenuItem{
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important; 
}
.ticketMenu .ticketMenuItem .ticketMenuName{
         margin-top: 3px;
}
.ticketMenu .ticketMenuItem:hover{
        background-color: #101924;
}

.ticketMenu .ticketMenuItemSave{
    background-color:#1ee0ac; 
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important;
}

.ticketMenu .ticketMenuItemSave:hover{
    background-color: #19be92;
}


</style>