<template>
    <div v-if="timeInStatusSummary">   
        <div class="d-flex flex-column h-100">
            <div class="card-title-group mb-3">
                <div class="card-title">
                    <h6 class="title">Status Summary</h6>
                </div>
            </div>
            <div class="progress-list gy-3">
                <div v-for="tiss in timeInStatusSummary" v-bind:key="tiss.statusId" class="progress-wrap">
                    <div class="progress-text">
                        <div class="progress-label">{{tiss.status}} (<small class="text-muted">{{tiss.timeInStatusHHMMSS}}</small>)</div>
                    </div>
                    <a-progress :strokeColor="tiss.color" :showInfo="false" :percent="tiss.percentageInStatus"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        ticketId: Number
    },
    data() {
        return {
            timeInStatusSummary: null,
        }
    },
    watch : {
          ticketId () {
              this.getTimeInStatusSummary()
          } 
    },
    created() {
        this.getTimeInStatusSummary()
    },
    methods: {
        getTimeInStatusSummary() {
            this.$http.get('/desk/Get_Ticket_TimeInStatus/' + this.ticketId)
            .then((response) => {
            this.timeInStatusSummary = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })  
        }
    }
}
</script>

<style>

</style>