<template>
  <div v-if="ticketStatusTimelines" :style="{ overflow: 'hidden', position: 'relative'}">
        <div class="card-title-group mb-3">
            <div class="card-title">
                <h6 class="title">Timeline</h6>
            </div>
        </div>
        <div class="timeline">
        <h6 class="timeline-head"></h6>
        <ul class="timeline-list">
            <li v-for="ticketStatusTimeline in ticketStatusTimelines" v-bind:key="ticketStatusTimeline.id" class="timeline-item">
                <div class="timeline-status" :style="{backgroundColor: ticketStatusTimeline.color}"></div>
                <div class="timeline-date"> {{ $moment.utc(ticketStatusTimeline.createdDate).locale('en').fromNow() }}</div>
                <div class="timeline-data" style="width: 100%">
                    <a-button v-if="ticketStatusTimeline.stopTime && $hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3')" class="float-right btn btn-dark btn-sm" @click="selectTicketStatus(ticketStatusTimeline)"><em class="icon ni ni-edit"/> Edit</a-button>
                    <h6 class="timeline-title"><em class="icon ni ni-piority"/> {{ticketStatusTimeline.status}}</h6>
                    <div class="timeline-des">
                        <h6 class="timeline-title" style="cursor: pointer;" @click="getUserStats(ticketStatusTimeline)"><em class="icon ni ni-user-alt"/> {{ticketStatusTimeline.createdByuser}}</h6>
                        <div class="timeline-des" v-if="ticketStatusTimeline.showUserSummary">
                            <hr/>
                            <userTimeInStatusSummary :ticketId="params.ticketId" :userId="params.userId" />
                            <hr/>
                        </div>
                        <p><em class="icon ni ni-calendar"/> {{ $moment.utc(ticketStatusTimeline.createdDate).local().format('HH:mm a DD/MM/YYYY') }}</p>
                        <p v-if="ticketStatusTimeline.stopTime"><em class="icon ni ni-calendar-fill"/> {{ $moment.utc(ticketStatusTimeline.stopTime).local().format('HH:mm a DD/MM/YYYY') }}</p>
                        <p><em class="icon ni ni-alarm-alt"/> {{ ticketStatusTimeline.timeInStatusHHMMSS }}</p>
                    </div>
                </div>
            </li>
        </ul>
        <div id="testxxx" style="position:relative;">
        </div>
    </div>
    <a-modal title="Edit Status Time" v-model="visible">
        <div class="row" v-if="selectedTicketStatusTimeline">
            <div class="col-lg-12">
                <div class="form-label">Change stop time on status</div>
                <a-date-picker style="width:100%;" :showTime="true" format="HH:mm a DD/MM/YYYY Z" :defaultValue="$moment.utc(selectedTicketStatusTimeline.stopTime).locale('en')" v-model="selectedTicketStatusTimeline.stopTime"></a-date-picker>
            </div>
        </div>
        <template slot="footer">
            <a-button class="btn btn-success btn-sm" @click="saveStatusChange"><em class="icon ni ni-save"/> Save</a-button>
        </template>
    </a-modal>
  </div>
</template>

<script>
import userTimeInStatusSummary from '@/components/desk/tickets/userTimeInStatusSummary.vue'

export default {
    components: { userTimeInStatusSummary},
    props:{
        ticketId: Number
    },
    data() {
        return {
            ticketStatusTimelines: null,
            params: {ticketId: null, userId: null},
            showUserInStatusSummary: false,
            visible: false,
            selectedTicketStatusTimeline: {}
        }
    },
    watch : {
          ticketId () {
              this.getTimeline()
          } 
    },
    created() {
        this.getTimeline()
    },
    methods: {
        selectTicketStatus(status) {
            this.selectedTicketStatusTimeline = status
            this.visible = true
        },
        saveStatusChange() {
            this.$http.post('/desk/updateTicketStatusStopTime', this.selectedTicketStatusTimeline)
            .then(() => {
                this.getTimeline()
                this.visible = false
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }) 
        },
        getTimeline() {
            this.$http.get('/desk/Get_Ticket_Status_Timeline/' + this.ticketId)
            .then((response) => {
            this.ticketStatusTimelines = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })  
        },
        getUserStats(t) {
           
            this.ticketStatusTimelines.forEach(tline => {
                if (tline.showUserSummary === true && tline.id != t.id){
                    tline.showUserSummary = false
                }
                if (tline.id === t.id) {
                    if (tline.showUserSummary === true ) {
                        t.showUserSummary = false
                    } else 
                    {
                        t.showUserSummary = true
                    }
                } 
            })
            this.params.ticketId = this.ticketId
            this.params.userId = t.createdByUserId
            
            // this.showUserInStatusSummary = true
        },
        closeUserSummary() {
            this.showUserInStatusSummary = false
        }
    }
}
</script>

<style>

</style>