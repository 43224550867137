<template>
  <div >
    <ticketmenu  :ticketId="int_Ticket.id" :completedDate="int_Ticket.completedDate" @closeTicket="closeTicket()" @completeTicket="completeTicket()" @saveTicket="saveTicket()" @changeTabs="changeTabs"></ticketmenu>
    

    <div v-if="int_Ticket" class="col-12" style="padding:20px;">
      <!-- <a-tabs v-model="activeTabKey" :default-active-key="1"> -->
      <div v-if="activeTabKey === 1" >
                  <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-building" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Account</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select  show-search option-filter-prop="children" placeholder="Select An Account" @change="accountChanged" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="int_Ticket.accountId" :class="{'is-error' : $v.int_Ticket.accountId.$invalid }">
                            <a-select-option v-for="acc in accounts" v-bind:key="acc.id">
                                {{ acc.text }}
                            </a-select-option>
                          </a-select>
                      </div>

                      <div v-if="this.int_Ticket.id === null && !accountIssueTypeUsage">
                        <small class="pl-2 text-danger" v-if="!accountIssueTypeUsage">This account has no remaining allowance. This will be billable.</small>
                      </div>

                      <div v-if="this.int_Ticket.id === null && accountIssueTypeUsage">
                        <small class="pl-2 text-success" v-if="accountIssueTypeUsage.remaining > 0">This ticket will be subtracted from this account's allowance.</small>
                        <small class="pl-2 text-danger" v-if="accountIssueTypeUsage.remaining <= 0 || accountIssueTypeUsage == null">This account has no remaining allowance. This will be billable.</small>
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="int_Ticket.accountId" class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-user-fill" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Contact</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                        <a-input-group compact>
                          <a-button style="width: 15%; border-style: solid; height: 34px " @click="showContactInfo(int_Ticket.contactId)"><em class="icon ni ni-edit" style="fontSize: 16px;"/></a-button>
                          <a-select show-search option-filter-prop="children" class="invisibox" style="min-width: 300px; width: 85%;" v-model="int_Ticket.contactId" :class="{'is-error' : $v.int_Ticket.contactId.$invalid }">
                            <a-select-option :key="0" @click="showContactInfo(int_Ticket.contactId)">New Contact</a-select-option>
                            <a-select-option v-for="con in contacts" v-bind:key="con.id">
                                {{ con.firstName + ' ' + con.lastName }}
                            </a-select-option>
                          </a-select>
                        </a-input-group>
                      </div>
                  </div>
              </div>
          </div>
         <!-- <div class="row g-3 align-center">
             <div class="col-12">
                 <div class="form-group">
                      <a-textarea
                      style="
                      font-weight:500;
                      overflow-y: hidden;
                      color: #5A1924;
                      font-size: 25px;
                      padding:0px;"
                      class="invisibox mb-1"
                      v-model="int_Ticket.title"
                      placeholder="Name your ticket"
                      :auto-size="{ minRows: 1, maxRows: 6 }"
                      />
                 </div>
             </div>
         </div> -->
          <div class="row g-3 align-center" style="position:relative;">
              <div class="col-3">
                  <div class="form-group" style="margin-top: -11px;">
                    <em class="icon ni ni-edit" style="padding-right: 5px;"/>
                    <label class="form-label" for="businessOwnership">Issue</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-textarea
                            class="invisibox"
                            style="width: 100%;"
                            v-model="int_Ticket.title"
                            placeholder="Provide some details for this ticket"
                            :auto-size="{ minRows: 2, maxRows: 6 }"
                            :class="{'is-error' : $v.int_Ticket.title.$invalid }"
                          />
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center" style="position:relative;">
              <div class="col-3">
                  <div class="form-group pt-1" style="">
                    <em class="icon ni ni-coins" style="padding-right: 5px;"/>
                    <label class="form-label" for="businessOwnership">Chargable </label>
                  </div>
              </div>
              
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-checkbox v-model="int_Ticket.decrementsIssueAllowance"></a-checkbox>
                      </div>
                  </div>
              </div>
          </div>
          <hr class="preview-hr">
                    <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-label" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Status</label>
                      <a-popover class="float-right" style="padding-top: 4px">
                        <template slot="content">
                          <p>This sets the status of a ticket.</p>
                          <p>This will determine which column the ticket is in the queue.</p>
                          <p>Also provides logging of status data.</p>
                        </template>
                        <span class="ni ni-info"/>
                      </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select @change="updateTicketStatus()" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="int_Ticket.statusId" :class="{'is-error' : $v.int_Ticket.statusId.$invalid }">
                            <a-select-option key="0">Select a status...</a-select-option>
                            <a-select-option v-for="stat in status" v-bind:key="stat.id">
                                {{ stat.text }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-piority-fill" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Issue Type</label>
                      <a-popover class="float-right" style="padding-top: 4px">
                        <template slot="content">
                          <p>This sets the issue type of a ticket. Default can be set on the account.</p>
                          <p>This will determine which ticket/time allocation is used.</p>
                        </template>
                        <span class="ni ni-info"/>
                      </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                       <a-select :disabled="!int_Ticket.accountId" @change="issueTypeChanged()" class="invisibox" style="min-width: 300px; width: 100%;" placeholder="Select Issue Type" v-model="int_Ticket.issueTypeId" :class="{'is-error' : $v.int_Ticket.issueTypeId.$invalid }">
                          <a-select-option key="0">Select an Issue Type...</a-select-option>
                          <a-select-option  v-for="it in issueTypes" v-bind:key="it.id">
                              {{ it.name }}
                          </a-select-option>
                        </a-select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-piority" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Priority</label>
                      <a-popover class="float-right" style="padding-top: 3px">
                        <template slot="content">
                          <p>This sets the priority of a ticket. Default can be set on the account.</p>
                          <p>This will determine the SLA and position in the queue.</p>
                        </template>
                        <span class="ni ni-info"/>
                      </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                       <a-select class="invisibox" style="min-width: 300px; width: 100%;" v-model="int_Ticket.priorityId" :class="{'is-error' : $v.int_Ticket.priorityId.$invalid }">
                          <a-select-option key="0">Select a priority...</a-select-option>
                          <a-select-option  v-for="priority in priorities" v-bind:key="priority.id">
                              {{ priority.text }}
                          </a-select-option>
                        </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <hr class="preview-hr">
         <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-user-alt" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Assign User</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select show-search option-filter-prop="children" class="invisibox" style="min-width: 300px; width: 100%;"  v-model="int_Ticket.assignedUserId">
                            <a-select-option :key="null">Select A User</a-select-option>
                            <a-select-option v-for="u in users" v-bind:key="u.id">
                                {{ u.firstName + ' ' + u.lastName }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-users" style="padding-right: 5px;"/>
                      <label class="form-label" for="default-01">Assign Team</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-select class="invisibox" style="min-width: 300px; width: 100%;"  v-model="int_Ticket.teamId">
                            <a-select-option :key="0">Select A Team</a-select-option>
                            <a-select-option v-for="t in teams" v-bind:key="t.id">
                                {{ t.text }}
                            </a-select-option>
                          </a-select>
                      </div>
                  </div>
              </div>
          </div>

          <hr class="preview-hr">
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-calendar" style="padding-right: 5px;"/>
                      <label class="form-label" for="businessOwnership">Booked Date</label>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <a-date-picker class="invisibox" :show-time="{format: 'HH:mm'}" v-model="int_Ticket.bookedDate" format="H:mm DD/MM/YYYY" />
                      </div>
                  </div>
              </div>
          </div>
          <div class="row g-3 align-center">
              <div class="col-3">
                  <div class="form-group" style="margin-top: 3px;">
                    <em class="icon ni ni-tag" style="padding-right: 3px;"/>
                       <label class="form-label mr-2" for="businessOwnership">Tags</label>
                       <a-popover style="cursor:pointer;" v-model="displayAddTag" trigger="click" >
                            <template slot="title" >
                              <em style="margin-top: 4px; margin-right: -5px; cursor: pointer;" class="icon ni ni-cross-c float-right" @click="closeAddTag"></em>
                              <span>Add A New Tag</span>
                            </template>
                          <template  slot="content">

                            <div class="row" style="width:290px;">
                              <div class="col-12"><a-input class="invisibox" v-model="newTag.name" placeholder="Name" /></div>
                              <div class="col-12"><vueswatches inline swatchSize="30" v-model="newTag.color" /></div>
                              <div class="col-12">
                                <button :disabled="newTag.color === '' || newTag.name === ''" v-on:click="addTicketTag()" class="btn btn-sm btn-block btn-success float-right"><em class="icon ni ni-save mr-1"></em> Add New Tag</button>
                              </div>
                            </div>
                          </template>
                          <em class="icon ni ni-plus-circle"></em>
                        </a-popover>
                  </div>
              </div>
              <div class="col-lg-9">
                  <div class="form-group">
                      <div class="form-control-wrap">
                          <multiselect v-model="int_Ticket.tags" :options="tags" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                            <template slot="selection" ><span :style="{backgroundColor: tgs.color}" class="multiselect__tag" v-for="tgs in int_Ticket.tags" v-bind:key="tgs.id">{{tgs.name}}</span></template>
                          </multiselect>
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="showScheduleStatusChange == true">
            <hr class="preview-hr">
            <div class="row g-3 align-center">
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label mr-2"><em class="icon ni ni-calendar-fill"/> Status Change Date</label>
                </div>
              </div>
              <div class="col-9">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-date-picker class="invisibox" show-time v-model="int_Ticket.scheduledDate" />
                    </div>
                </div>
              </div>
            </div>
            <div class="row g-3 align-center">
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label mr-2"><em class="icon ni ni-label"/> Status To</label>
                </div>
              </div>
              <div class="col-9">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-select class="invisibox" style="min-width: 300px; width: 100%;"  v-model="int_Ticket.statusToId">
                          <a-select-option v-for="stat in status" v-bind:key="stat.id">
                              {{ stat.text }}
                          </a-select-option>
                        </a-select>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3 align-center">
            <div class="col-3">
              <div class="form-group">
                <label class="form-label mr-2"><em class="icon ni ni-calendar"/> Schedule Status Change</label>
              </div>
            </div>
            <div class="col-9">
              <div class="form-group">
                  <div class="form-control-wrap">
                      <a-switch v-model="showScheduleStatusChange" />
                  </div>
              </div>
            </div>
          </div>
          
          
          
          
         <!-- <div class="form-group">
             <label class="form-label" for="default-01">Tags
                
            </label>
             <div class="form-control-wrap">
               <tags-input
               element-id="tags"
               v-model="int_Ticket.tags"
               :existing-tags="tags"
               :id-field="'id'"
               :text-field="'name'"
               :typeahead="true"
               :typeahead-always-show="true"
               :only-existing-tags="true"
               >
                <template v-slot:tag="tag">
                    #{{ tag.tag.value }}
                </template>
               <template v-slot:selected-tag="{ tag, index, removeTag }">
                  <span v-html="tag.name"></span>

                  <a v-show="!disabled"
                      href="#"
                      class="tags-input-remove"
                      @click.prevent="removeTag(index)"></a>
              </template>
               </tags-input>
             </div>
         </div> -->
      </div>
      <div v-if="activeTabKey === 2" tab="Notes" force-render>
        <div v-if="int_Ticket.id">
            <notes  
              :id="int_Ticket.id"
              :entityName="'ticket'"
              :getEndpoint="'/desk/Get_Ticket_Notes/' + int_Ticket.id" 
              :saveEndpoint="'/desk/Save_Ticket_Note/'"
              :deleteEndpoint="'/desk/Delete_Ticket_Note/'">
            </notes>
        </div>
        <div v-if="!int_Ticket.id">
          <p class="text-center">Please save the ticket before adding notes.</p>
        </div>
      </div>
      <div v-if="activeTabKey === 3" tab="Attachments">
              <div v-if="int_Ticket.id">
                    <attachments  
                      :id="int_Ticket.id"
                      :entityName="'ticket'"
                      :getEndpoint="'/desk/Get_Ticket_Attachments/' + int_Ticket.id">
                    </attachments>
                    </div>
                    <div v-else>
                      <p class="text-center">Please save the ticket before adding attachments.</p>
                    </div>
      </div>
      <div v-if="activeTabKey === 4" tab="Status Timeline">
        <div class="row" v-if="int_Ticket.id">
          <div class="col-12">
            <timeInstatussummary :ticketId="int_Ticket.id"></timeInstatussummary>
          </div>
        </div>
        
        <div class="row" v-if="int_Ticket.id">
          <div class="col-12">
            <statusTimeline :ticketId="int_Ticket.id"/>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <p class="text-center">Please save the ticket before accessing status timelines.</p>
          </div>
        </div>

      </div>

      <div v-if="activeTabKey === 6" tab="Time Allocation">
        <div class="row" v-if="int_Ticket.id">
          <div class="col-12">
            <timeAllocated @reRender="reRender()" :ticketId="int_Ticket.id"></timeAllocated>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <p class="text-center">Please save the ticket before accessing time allocations.</p>
          </div>
        </div>

      </div>
      
      <div v-if="activeTabKey === 5" tab="Scheduled Status Automations">
        <div class="row" v-if="int_Ticket.id">
          <div class="col-12">
            <ticketScheduledStatusChange :ticketId="int_Ticket.id"></ticketScheduledStatusChange>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <p class="text-center">Please save the ticket before accessing scheduled automations.</p>
          </div>
        </div>

      </div>
    <!-- </a-tabs>   -->
     </div>
     <a-modal v-model="showContact" title="Contact Info">
       <div class="row">
         <div class="col-12">
            <label class="form-label">First Name</label>
            <a-input class="invisibox" v-model="selectedContact.firstName" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Last Name</label>
            <a-input class="invisibox" v-model="selectedContact.lastName" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Email</label>
            <a-input class="invisibox" v-model="selectedContact.email" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Phone</label>
            <a-input class="invisibox" v-model="selectedContact.phoneNumber" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
            <label class="form-label">Mobile</label>
            <a-input class="invisibox" v-model="selectedContact.mobile" />
         </div>
       </div>
       <div class="row">
         <div class="col-12">
          <label class="form-label">Authorisation Access</label>
          <table class="table">
              <tbody>
                  <tr v-for="ca in contactAuthAccess" :key="ca.id">
                      <td>{{ ca.name }}</td>
                  </tr>
              </tbody>
          </table>
         </div>
       </div>
       <template slot="footer">
         <a-button class="btn btn-sm btn-success" @click="saveContact()">
           <em class="icon ni ni-save"></em>Save
         </a-button>
       </template>
     </a-modal>
    
    <a-modal v-model="search" title="Search Accounts / Contacts">
       <div class="row">
         <div class="col-12">
            <input type="text" v-model="searchFilter" class="mb-2 form-control border-transparent form-focus-none" placeholder="Search Accounts / Contacts">
         </div>
       </div>
       <div class="row" v-for="a in filteredAccountsAndContacts" :key="a.id">
         <div class="col-12">
            <a-button style="width: 100%" @click="selectContact(a)">
              {{ a.name + ' | ' + a.accountName }}
            </a-button>
         </div>
       </div>
     </a-modal>
  </div>
</template>

<script>

import notes from '@/components/desk/tickets/ticketNotes.vue'
import attachments from '@/components/generic/attachments'
import statusTimeline from '@/components/desk/tickets/statusTimeline.vue'
import timeInstatussummary from '@/components/desk/tickets/timeInstatussummary.vue'
import timeAllocated from '@/components/desk/tickets/timeAllocated.vue'
import ticketmenu from '@/components/desk/tickets/ticketmenu'
import ticketScheduledStatusChange from '@/components/desk/tickets/ticketScheduledStatusChange.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    notes, attachments, statusTimeline, timeInstatussummary, ticketmenu, ticketScheduledStatusChange, timeAllocated
  },
  props: {
    ticket: Object,
    defaultStatusId: Number,
    newTicket: {
      type: Boolean
    },
  },
  validations: {
    int_Ticket: {
      accountId: {
        required
      },
      title: {
        required
      },
      contactId: {
        required
      },
      statusId: {
        required
      },
      issueTypeId: {
        required
      },
      priorityId: {
        required
      }
    }
  },
  data () {
      return {
        accountIssueTypeUsage: '',
        searchFilter: '',
        accountsAndContacts: null,
        search: false,
        contactAuthAccess: [],
        activeTabKey: 1,
        showContact: false,
        selectedContact: {},
        tag: "",
        displayAddTag: false,
        newTag: {name: '',color: ''},
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
        priorities: [],
        status: [],
        int_Ticket: {id: null, statusId: null, issueTypeId: null, assignedUserId: null,teamId: null, priorityId: null, decrementsIssueAllowance: true},
        accounts:[],
        contacts: [],
        issueTypes: [],
        users: [],
        teams: [],
        tags: [],
        showScheduleStatusChange: false,
        authorisationAccesses: [],
        testTags: [{ "id": 1, "name": "BlueTag", "createdByUserId": null, "color": "#3398DB" }],
      }
  },
  created () {
    this.activeTabKey = 1
     if (this.ticket != null && this.ticket.id !== null) {       
      this.int_Ticket.id  = this.ticket.id 
      this.getTicket()
    } else {
      this.resetTicket()
      if (this.defaultStatusId != null) {
        this.int_Ticket.statusId  = this.defaultStatusId
      }
    }
    this.getLists()
  },
  computed: {
        filteredAccountsAndContacts: function() {
            if (this.searchFilter != '') {
                return this.accountsAndContacts.filter((f) => {  return f.name.toLowerCase().match(this.searchFilter.toLowerCase()) })
            }
            return this.accountsAndContacts 
        }
    },
  watch: {
          ticket:function() {
              this.activeTabKey = 1
              if (this.ticket != null && this.ticket.id !== null) {
               this.int_Ticket.id  = this.ticket.id 
               this.getTicket()
             } else {
               this.resetTicket()
                if (this.defaultStatusId != null) {
                  this.int_Ticket.statusId  = this.defaultStatusId
                }
             }
          },
          newTicket:function() {
              this.activeTabKey = 1
              if (this.ticket != null && this.ticket.id !== null) {
                this.int_Ticket.id  = this.ticket.id 
                this.getTicket()
              } else {
               this.resetTicket()
                if (this.defaultStatusId != null) {
                  this.int_Ticket.statusId  = this.defaultStatusId
                }
             }
          }
        },
  methods: {
    issueTypeChanged () {
      this.get_IssueUsage()
    },
    reRender() {
      this.$emit('ticketSaved')
    },
    accountChanged () {
      this.getAccountDefaults()
      this.getContacts()
    },
    get_IssueUsage () {
      if (this.int_Ticket.accountId != null && this.int_Ticket.issueTypeId != null) {
        let search  = {tenantTicketIssueTypeId: this.int_Ticket.issueTypeId, accountId: this.int_Ticket.accountId}
        this.$http.post('/accounts/Get_Account_IssueTypeAllowance_Usage_ByDate/', search)
        .then((response) => {
          this.accountIssueTypeUsage = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })  
      }
    },
    selectContact(c){
      this.int_Ticket.accountId = c.accountId
      this.int_Ticket.contactId = c.id
      this.search = false
    },
    resetTicket (){
       this.tags = []
       this.int_Ticket  = {id: null, statusId: null, issueTypeId: null, assignedUserId: null,teamId: null, priorityId: null, accountId: null, accountIssueTypeUsage: null, decrementsIssueAllowance: true}
    },
    completeTicket () {
       this.$http.post('/desk/Complete_Ticket/', this.int_Ticket)
      .then((response) => {
        this.int_Ticket = response.data
        this.$emit('ticketSaved')
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    changeTabs (tabName) {
      if (tabName === 'details'){ this.activeTabKey = 1}
      if (tabName === 'notes'){ this.activeTabKey = 2 }
      if (tabName === 'attachments'){ this.activeTabKey = 3 }
      if (tabName === 'timeline'){ this.activeTabKey = 4 }
      if (tabName === 'timeAllocation'){ this.activeTabKey = 6 }
      if (tabName === 'status'){ this.activeTabKey = 5 }
    },
    closeAddTag() {
      this.displayAddTag = false
    },
    addNewContact() {
      this.$http.get('/contacts/get_Contact/')
      .then((response) => {
          this.selectedContact = response.data
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
      this.showContact = true
    },
    getContactAuthAccess(id) {
        this.$http.get('/contacts/get_ContactAuthAccess/' + id)
        .then((response) => {
            this.contactAuthAccess = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
    },
    showContactInfo(id){
      if (id != null) {
        let urI = id ? ('/contacts/get_Contact/' + id ) : '/contacts/get_Contact/' 
        this.$http.get(urI)
        .then((response) => {
            this.getContactAuthAccess(id)
            this.selectedContact = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
      }
      this.showContact = true
    },
    saveTicket() {
      if (this.int_Ticket.accountId ===  null) {
        this.$message.error('You must assign an account before saving the ticket')
        return false
      }
      if (this.int_Ticket.issueTypeId ===  null) {
        this.$message.error('You must select an Issue Type before saving the ticket')
        return false
      }
      if (this.int_Ticket.priorityId ===  null) {
        this.$message.error('You must select a priority before saving the ticket')
        return false
      }
      if (this.accountIssueTypeUsage === null) {
        this.$message.error('Please ensure you bill the account for this time')
      }
      if (this.accountIssueTypeUsage.remaining <= 0) {
        this.$message.error('Please ensure you bill the account for this time')
      }

    if (this.int_Ticket.id) {
      if (this.$v.int_Ticket.$invalid) {
        this.$message.error('Please check for any form errors.')
      } else {
        if ( this.showRecurringSettings == true ) {
          this.updateRecurringTicketTemplate()
        } else {
          this.updateTicket()
        }
      }
    } else {
      if (this.$v.int_Ticket.$invalid) {
        this.$message.error('Please check for any form errors.')
      } else {
        if (this.showRecurringSettings == true) {
          this.addRecurringTicketTemplate()
        } else {
          this.addTicket()
        }
      }
    }
    },
    closeTicket () {
      this.$emit('ticketSaved')
    },
    addTicket () {
      // add the change Id to avoid any clashes with signalR back and forth.
      let changeId  = this.$uuid.v4()
      this.int_Ticket.changeId  = changeId
      this.$store.commit('addTicketChange',changeId)
      // persist in the DB
      this.$http.post('/desk/Add_Ticket/', this.int_Ticket)
      .then(() => {
        this.resetTicket()
        this.$emit('ticketSaved')
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    addTicketTag () {
       this.$http.post('/atr/Add_Ticket_Tag/', this.newTag)
      .then((response) => {
        this.int_Ticket.tags.push(response.data)
        this.tags.push(response.data)
        this.newTag  = {name: '',color: ''}
        this.displayAddTag = false
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    updateTicket () {
      // Log the local change
      let changeId  = this.$uuid.v4()
      this.int_Ticket.changeId  = changeId
      this.$store.commit('addTicketChange',changeId)
      // persist in the database.
      this.$http.post('/desk/Update_Ticket/', this.int_Ticket)
      .then(() => {
          this.$emit('ticketSaved')
          this.$message.success('The ticket has been updated')
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    getTicket () {
      this.$http.get('/desk/get_Ticket/' + this.int_Ticket.id)
      .then((response) => {
        this.int_Ticket = response.data
        if (this.int_Ticket.bookedDate) {
          this.int_Ticket.bookedDate = this.$moment.utc(this.int_Ticket.bookedDate).local() //to account for gmt/bst time
        }
        this.getContacts()
        this.getAccountTicketIssueTypes()
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    },
    gettags () {
        this.$http.get('/lists/Get_Ticket_Tags')
        .then((response) => {
          this.tags = response.data
          })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    updateTicketStatus() {
      if (this.int_Ticket.id){ // only update the status if the ticket is already saved
        this.$http.post('/desk/updateTicketStatus', this.int_Ticket)
        .then(() => {
          this.$message.success('Status Updated and saved')
          })
        .catch(() => {
          this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
        })
      }
    },
    getAccountTicketIssueTypes(){
      this.$http.get('/accounts/Get_Account_IssueTypes/' + this.int_Ticket.accountId)
        .then((response) => {
            this.issueTypes = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
    },
    getLists(){
        this.gettags()
        this.$http.get('/lists/Get_List/ticketPriorities')
        .then((response) => {
          this.priorities = response.data
          })
        .catch(() => {
          this.$message.error('There has been an error')
        })
        
        this.$http.get('/desk/get_AccountsAndContacts')
        .then((response) => {
            this.accountsAndContacts = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/teams')
        .then((response) => {
            this.teams = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/authorisationAccess')
          .then((response) => {
              this.authorisationAccesses = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/accounts')
        .then((response) => {
            this.accounts = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/ticketStatus')
        .then((response) => {
            this.status = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_Users')
        .then((response) => {
            this.users = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      getAccountDefaults() {
        this.$http.get('/accounts/Get_Account_Defaults/' + this.int_Ticket.accountId)
        .then((response) => {
            this.int_Ticket.priorityId = response.data[0].defaultTicketPriority
            this.int_Ticket.issueTypeId = response.data[0].defaultTicketIssueType
            this.accountIssueTypeUsage = null
            this.get_IssueUsage()
            this.getAccountTicketIssueTypes()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      getContacts() {
        this.$http.get('/accounts/Get_Account_Contacts/' + this.int_Ticket.accountId)
        .then((response) => {
            this.contacts = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      saveContact () {
        if (this.selectedContact.id != null)    {
            this.updateContact()
        } 
        else {
            this.saveNewContact()
        }
      },
      saveNewContact() {
        this.selectedContact.accountId = this.int_Ticket.accountId
        this.$http.post('/contacts/Add_Contact', this.selectedContact)
        .then((resp) => {
            this.int_Ticket.contactId = resp.data.id
            this.$message.success('Contact Created')
            this.getContacts()
            this.showContact = false
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
      },
      updateContact() {
        this.$http.post('/contacts/Update_Contact', this.selectedContact)
        .then(() => {
            this.$message.success('Contact Updated')
            this.getContacts()
            this.showContact = false
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
      },
  },
}
</script>

<style>
 
.nk-sticky-toolbar {
    position: absolute;
    width:100%;
    border: 3px solid #fff;
    top: 0px;
    background: #fff;
    z-index: 3000;
    left: 0px;
    border-radius: 6px 0 0 6px;
    border-right: 0;
    box-shadow: -2px 0 24px -2px rgb(43 55 72 / 15%);
}
.nk-sticky-toolbar li:not(:last-child) {
    border-bottom: 2px solid #fff;
    float:left;
}

.headbtnx {
    background-color: rgba(85,155,251,0.1);
    color: #559bfb;
}

.nk-sticky-toolbar li a {
    display: flex;
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: inherit;
}
.footer {
  position: fixed;
  bottom: 0;
  margin: 1em;
  width: 21%;
  max-width: 21%;
  max-height: 95%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>